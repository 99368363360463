var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "editor-wrapper" }, [
    _c(
      "div",
      { staticClass: "editor", class: { invalid: _vm.valid === false } },
      [
        _c("editor-menu-bar", {
          attrs: { editor: _vm.editor },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ commands, isActive }) {
                return _c(
                  "div",
                  { staticClass: "menubar" },
                  [
                    _vm.title
                      ? _c("div", { staticClass: "editor-title" }, [
                          _vm._v(_vm._s(_vm.title)),
                        ])
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        staticClass: "menubar__button",
                        class: { "is-active": isActive.bold() },
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            _vm.toolbarClick()
                            commands.bold()
                          },
                        },
                      },
                      [_c("b", [_vm._v("B")])]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "menubar__button",
                        class: { "is-active": isActive.italic() },
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            _vm.toolbarClick()
                            commands.italic()
                          },
                        },
                      },
                      [_c("i", [_vm._v("I")])]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "menubar__button",
                        class: { "is-active": isActive.underline() },
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            _vm.toolbarClick()
                            commands.underline()
                          },
                        },
                      },
                      [_c("u", [_vm._v("U")])]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "menubar__button",
                        class: { "is-active": isActive.heading({ level: 1 }) },
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            _vm.toolbarClick()
                            commands.heading({ level: 1 })
                          },
                        },
                      },
                      [_vm._v(" H1 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "menubar__button",
                        class: { "is-active": isActive.heading({ level: 2 }) },
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            _vm.toolbarClick()
                            commands.heading({ level: 2 })
                          },
                        },
                      },
                      [_vm._v(" H2 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "menubar__button",
                        class: { "is-active": isActive.heading({ level: 3 }) },
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            _vm.toolbarClick()
                            commands.heading({ level: 3 })
                          },
                        },
                      },
                      [_vm._v(" H3 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "menubar__button",
                        class: { "is-active": isActive.bullet_list() },
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            _vm.toolbarClick()
                            commands.bullet_list()
                          },
                        },
                      },
                      [_vm._v(" • ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "menubar__button",
                        class: { "is-active": isActive.ordered_list() },
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            _vm.toolbarClick()
                            commands.ordered_list()
                          },
                        },
                      },
                      [_vm._v(" 1. ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "menubar__button",
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            _vm.toolbarClick()
                            commands.horizontal_rule()
                          },
                        },
                      },
                      [_vm._v(" — ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "menubar__button",
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            _vm.toolbarClick()
                            commands.undo()
                          },
                        },
                      },
                      [_c("b", [_vm._v("↩")])]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "menubar__button",
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            _vm.toolbarClick()
                            commands.redo()
                          },
                        },
                      },
                      [_c("b", [_vm._v("↪")])]
                    ),
                  ],
                  1
                )
              },
            },
          ]),
        }),
        _c("editor-content", {
          staticClass: "editor_content",
          attrs: { editor: _vm.editor },
        }),
      ],
      1
    ),
    _vm.valid === false
      ? _c("div", { staticClass: "invalid-message" }, [
          _vm._v(_vm._s(_vm.invalidMessage)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }