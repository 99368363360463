<template>
  <div class="editor-wrapper">
    <div class="editor" :class="{invalid: valid === false}">
      <editor-menu-bar :editor="editor">
        <div class="menubar" slot-scope="{ commands, isActive }">
          <div v-if="title" class="editor-title">{{title}}</div>
          <el-button size="mini" class="menubar__button" :class="{ 'is-active': isActive.bold() }" @click="toolbarClick(); commands.bold()">
            <b>B</b>
          </el-button>

          <el-button size="mini" class="menubar__button" :class="{ 'is-active': isActive.italic() }" @click="toolbarClick(); commands.italic()">
            <i>I</i>
          </el-button>

          <el-button size="mini" class="menubar__button" :class="{ 'is-active': isActive.underline() }" @click="toolbarClick(); commands.underline()">
            <u>U</u>
          </el-button>

          <el-button size="mini" class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 1 }) }" @click="toolbarClick(); commands.heading({ level: 1 })">
            H1
          </el-button>

          <el-button size="mini" class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 2 }) }" @click="toolbarClick(); commands.heading({ level: 2 })">
            H2
          </el-button>

          <el-button size="mini" class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 3 }) }" @click="toolbarClick(); commands.heading({ level: 3 })">
            H3
          </el-button>

          <el-button size="mini" class="menubar__button" :class="{ 'is-active': isActive.bullet_list() }" @click="toolbarClick(); commands.bullet_list()">
            &bullet;
          </el-button>

          <el-button size="mini" class="menubar__button" :class="{ 'is-active': isActive.ordered_list() }" @click="toolbarClick(); commands.ordered_list()">
            1.
          </el-button>

          <el-button size="mini" class="menubar__button" @click="toolbarClick(); commands.horizontal_rule()">
            &mdash;
          </el-button>

          <el-button size="mini" class="menubar__button" @click="toolbarClick(); commands.undo()">
            <b>↩</b>
          </el-button>

          <el-button size="mini" class="menubar__button" @click="toolbarClick(); commands.redo()">
            <b>↪</b>
          </el-button>

        </div>
      </editor-menu-bar>

      <editor-content class="editor_content" :editor="editor" />
    </div>
    <div class="invalid-message" v-if="valid === false">{{invalidMessage}}</div>
  </div>
</template>

<script>
    import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
    import {
      HardBreak,
      Heading,
      HorizontalRule,
      OrderedList,
      BulletList,
      ListItem,
      TodoItem,
      TodoList,
      Bold,
      Italic,
      Link,
      Underline,
      History,
    } from 'tiptap-extensions'

    export default {
        name: 'rich-editor',
        components: {
          EditorContent,
          EditorMenuBar,
        },
        props: {
            title: {
              required: false,
              type: String,
            },
            content: {
                required: false,
                type: String,
            },
            valid: {
                required: false,
                type: Boolean,
                default: true,
            },
            invalidMessage: {
              required: false,
              type: String,
            }
        },
        data() {
          return {
            editor: new Editor({
              extensions: [
                new BulletList(),
                new HardBreak(),
                new Heading({ levels: [1, 2, 3] }),
                new HorizontalRule(),
                new ListItem(),
                new OrderedList(),
                new TodoItem(),
                new TodoList(),
                new Link(),
                new Bold(),
                new Italic(),
                new Underline(),
                new History(),
              ],
              content: this.content,
              onUpdate: this.update,
              onBlur: this.onBlur,
            }),
            exportContent: null,

            triggerBlur: null,
            temp: 0,
          }
        },
        watch: {
          content(val) {
            this.editor.setContent(val);
          }
        },
        methods: {
          toolbarClick() {
            // don't notify on blur if toolbar action clicked
            if(this.triggerNotify) {
              clearTimeout(this.triggerNotify);
              this.triggerNotify = null;
            }
          },
          update(state) {
            // check for empty content
            var content = state.getHTML();
            var json = state.getJSON().content;
            if (Array.isArray(json) && json.length === 1 && !Object.prototype.hasOwnProperty.call(json[0],"content")) {
              content = null;
            }
            this.exportContent = content;
          },
          onBlur() {
            this.temp = Date.now();
            if(this.exportContent != null) {
              clearTimeout(this.triggerNotify);
              // wait to see if a toolbar button was clicked before notifying of change
              this.triggerNotify = setTimeout(() => {
                this.triggerNotify = null;
                this.$emit('change',this.exportContent);
              },200);
            }
          },
        },
        beforeDestroy() {
          // Always destroy your editor instance when it's no longer needed
          this.editor.destroy()
        },
    };
</script>

<style lang="scss" scoped>
  .editor-wrapper {
    margin-bottom: 20px;
  }
  .editor {
    border: 1px solid #DCDFE6;
    border-radius: 5px;
    overflow: hidden;
    background: #F0F2F5;
    &.invalid {
      border-color: #F56C6C;
    }
  }
  .menubar {
    text-align: center;
    margin: 6px 6px 3px;
    .el-button {
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 3px;
    }
  }
  .editor-title {
    font-weight: 500;
    margin-bottom: 10px
  }
  .editor_content {
    border-top: 1px solid #DCDFE6;
    background: white;
    padding: 10px;
  }
  .invalid-message {
    color: #F56C6C;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
</style>
