var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showDialog
    ? _c(
        "el-dialog",
        {
          staticClass: "slideDialog",
          attrs: {
            visible: true,
            title: (_vm.slideData.id ? "Edit" : "Add") + " slide",
          },
          on: { close: _vm.closeDialog },
        },
        [
          _vm.uploadError
            ? _c("el-alert", {
                staticStyle: { "margin-bottom": "10px" },
                attrs: {
                  type: "error",
                  closable: false,
                  title: "Warning",
                  description:
                    "There was a problem uploading the selected file.",
                },
              })
            : _vm._e(),
          _c(
            "el-form",
            {
              ref: "slideForm",
              attrs: {
                model: _vm.slideData,
                rules: _vm.rules,
                autoComplete: "on",
                "label-position": "left",
                "label-width": "200px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "title", label: "Title" } },
                [
                  _c("el-input", {
                    attrs: {
                      name: "title",
                      type: "text",
                      placeholder: "Slide title",
                    },
                    model: {
                      value: _vm.slideData.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.slideData, "title", $$v)
                      },
                      expression: "slideData.title",
                    },
                  }),
                  _c("div", { staticClass: "footnote" }, [
                    _vm._v(
                      "Title is for admin reference, and is not displayed in the app."
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "type", label: "Slide type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.clearValidation },
                      model: {
                        value: _vm.slideData.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.slideData, "type", $$v)
                        },
                        expression: "slideData.type",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "question" } }, [
                        _vm._v("Question"),
                      ]),
                      _c("el-radio-button", { attrs: { label: "youtube" } }, [
                        _vm._v("Video"),
                      ]),
                      _c("el-radio-button", { attrs: { label: "image" } }, [
                        _vm._v("Image"),
                      ]),
                      _c("el-radio-button", { attrs: { label: "upload" } }, [
                        _vm._v("Photo upload"),
                      ]),
                      _c("el-radio-button", { attrs: { label: "pdf" } }, [
                        _vm._v("PDF"),
                      ]),
                      _vm.learning
                        ? _c(
                            "el-radio-button",
                            { attrs: { label: "activity" } },
                            [_vm._v("Activity")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slideData.type == "text",
                      expression: "slideData.type=='text'",
                    },
                  ],
                  attrs: { prop: "text", label: "Text content" },
                },
                [
                  _c("rich-editor", {
                    attrs: {
                      content: _vm.slideData.text,
                      valid: _vm.textValid,
                      "invalid-message":
                        "Please enter the text content for this slide",
                    },
                    on: { change: _vm.textChange },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slideData.type == "question",
                      expression: "slideData.type=='question'",
                    },
                  ],
                  attrs: { prop: "question", label: "Question" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      name: "question",
                      type: "textarea",
                      placeholder: "Text prompt",
                      rows: 3,
                    },
                    model: {
                      value: _vm.slideData.question,
                      callback: function ($$v) {
                        _vm.$set(_vm.slideData, "question", $$v)
                      },
                      expression: "slideData.question",
                    },
                  }),
                  _c("div", { staticClass: "footnote" }, [
                    _vm._v(
                      "The participant will be prompted to enter a response."
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slideData.type == "question" && _vm.learning,
                      expression: "slideData.type=='question' && learning",
                    },
                  ],
                  attrs: {
                    prop: "question_field_id",
                    label: "Assign response to profile",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.slideData.question_field_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.slideData, "question_field_id", $$v)
                        },
                        expression: "slideData.question_field_id",
                      },
                    },
                    _vm._l(_vm.profile_fields, function (field) {
                      return _c("el-option", {
                        key: field.id,
                        attrs: { label: field.name, value: field.id },
                      })
                    }),
                    1
                  ),
                  _c("div", { staticClass: "footnote" }, [
                    _vm._v("Leave blank if this is not a profile question."),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slideData.type == "youtube",
                      expression: "slideData.type=='youtube'",
                    },
                  ],
                  attrs: { prop: "youtube", label: "Youtube video ID" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Video ID or URL" },
                    model: {
                      value: _vm.slideData.youtube,
                      callback: function ($$v) {
                        _vm.$set(_vm.slideData, "youtube", $$v)
                      },
                      expression: "slideData.youtube",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slideData.type == "image",
                      expression: "slideData.type=='image'",
                    },
                  ],
                  staticClass: "imageUpload",
                  attrs: { prop: "image", label: "Image" },
                },
                [
                  _c("file-upload", {
                    attrs: {
                      filetype: "image",
                      current: _vm.slideData.initialImage,
                    },
                    on: {
                      select: _vm.handleImageSelected,
                      remove: _vm.handleImageRemoved,
                    },
                  }),
                  _c("div", { staticClass: "footnote" }, [
                    _vm._v("File size must be less than 2MB."),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slideData.type == "upload",
                      expression: "slideData.type=='upload'",
                    },
                  ],
                  attrs: { prop: "upload_intro", label: "Introduction" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      name: "upload_intro",
                      type: "textarea",
                      placeholder: "Paragraph",
                      rows: 3,
                    },
                    model: {
                      value: _vm.slideData.upload_intro,
                      callback: function ($$v) {
                        _vm.$set(_vm.slideData, "upload_intro", $$v)
                      },
                      expression: "slideData.upload_intro",
                    },
                  }),
                  _c("div", { staticClass: "footnote" }, [
                    _vm._v(
                      "Introductory paragraph describing the photos to upload."
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slideData.type == "upload",
                      expression: "slideData.type=='upload'",
                    },
                  ],
                  attrs: { prop: "upload_question", label: "Question" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      name: "upload_question",
                      type: "textarea",
                      placeholder: "Text prompt",
                      rows: 3,
                    },
                    model: {
                      value: _vm.slideData.upload_question,
                      callback: function ($$v) {
                        _vm.$set(_vm.slideData, "upload_question", $$v)
                      },
                      expression: "slideData.upload_question",
                    },
                  }),
                  _c("div", { staticClass: "footnote" }, [
                    _vm._v(
                      "The participant will be prompted to enter a response for each photo."
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slideData.type == "upload",
                      expression: "slideData.type=='upload'",
                    },
                  ],
                  attrs: { prop: "upload_count", label: "Number of photos" },
                },
                [
                  _c("el-input-number", {
                    attrs: { name: "upload_count", min: 1, max: 10 },
                    model: {
                      value: _vm.slideData.upload_count,
                      callback: function ($$v) {
                        _vm.$set(_vm.slideData, "upload_count", $$v)
                      },
                      expression: "slideData.upload_count",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slideData.type == "pdf",
                      expression: "slideData.type=='pdf'",
                    },
                  ],
                  attrs: { prop: "pdf", label: "PDF" },
                },
                [
                  _c("file-upload", {
                    attrs: {
                      filetype: "pdf",
                      current: _vm.slideData.initialPdf,
                    },
                    on: {
                      select: _vm.handlePDFSelected,
                      remove: _vm.handlePDFRemoved,
                    },
                  }),
                  _c("div", { staticClass: "footnote" }, [
                    _vm._v("File size must be less than 2MB."),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slideData.type == "activity" && _vm.learning,
                      expression: "slideData.type=='activity' && learning",
                    },
                  ],
                  attrs: { prop: "activity_prompt", label: "Prompt" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      name: "activity_prompt",
                      type: "textarea",
                      placeholder: "Text prompt",
                      rows: 3,
                    },
                    model: {
                      value: _vm.slideData.activity_prompt,
                      callback: function ($$v) {
                        _vm.$set(_vm.slideData, "activity_prompt", $$v)
                      },
                      expression: "slideData.activity_prompt",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slideData.type == "activity" && _vm.learning,
                      expression: "slideData.type=='activity' && learning",
                    },
                  ],
                  attrs: { prop: "activity_id", label: "Include activity" },
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showActivityList = true
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          this.activityName
                            ? this.activityName
                            : "Select an activity"
                        )
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "dialog-footer" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.slideData.id || _vm.slideData.temp_id,
                          expression: "slideData.id || slideData.temp_id",
                        },
                      ],
                      attrs: { type: "danger" },
                      on: { click: _vm.deleteSlide },
                    },
                    [_vm._v("Delete slide")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.updateSlide },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          this.slideData.id || _vm.slideData.temp_id
                            ? "Update"
                            : "Add"
                        ) + " slide"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              staticClass: "activityListDialog",
              attrs: {
                "append-to-body": "",
                visible: _vm.showActivityList,
                title: "Select an activity",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showActivityList = $event
                },
              },
            },
            [
              _c("activity-list", {
                attrs: { limit: 5 },
                on: { select: _vm.selectActivity },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }