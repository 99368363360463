<template>
    <el-dialog class="slideDialog" v-if="showDialog" :visible="true" @close="closeDialog" :title="(slideData.id ? 'Edit' : 'Add')+' slide'">

        <el-alert
                type="error"
                v-if="uploadError"
                :closable="false"
                title="Warning"
                description="There was a problem uploading the selected file."
                style="margin-bottom:10px"
        />

        <el-form :model="slideData" :rules="rules" ref="slideForm" autoComplete="on" label-position="left" label-width="200px">

            <el-form-item prop="title" label="Title">
              <el-input name="title" type="text" v-model="slideData.title" placeholder="Slide title" />
              <div class="footnote">Title is for admin reference, and is not displayed in the app.</div>
            </el-form-item>

            <el-form-item prop="type" label="Slide type">
              <el-radio-group v-model="slideData.type" @change="clearValidation">
                <!-- <el-radio-button label="text">Text</el-radio-button> -->
                <el-radio-button label="question">Question</el-radio-button>
                <el-radio-button label="youtube">Video</el-radio-button>
                <el-radio-button label="image">Image</el-radio-button>
                <el-radio-button label="upload">Photo upload</el-radio-button>
                <el-radio-button label="pdf">PDF</el-radio-button>
                <el-radio-button v-if="learning" label="activity">Activity</el-radio-button>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-show="slideData.type=='text'" prop="text" label="Text content">
              <rich-editor :content="slideData.text" @change="textChange" :valid="textValid" invalid-message="Please enter the text content for this slide" />
            </el-form-item>



            <el-form-item v-show="slideData.type=='question'" prop="question" label="Question">
              <el-input name="question" type="textarea" v-model="slideData.question" placeholder="Text prompt" :rows="3" />
              <div class="footnote">The participant will be prompted to enter a response.</div>
            </el-form-item>

            <el-form-item v-show="slideData.type=='question' && learning" prop="question_field_id" label="Assign response to profile">
              <el-select v-model="slideData.question_field_id">
                  <el-option v-for="field in profile_fields"
                             :key="field.id"
                             :label="field.name"
                             :value="field.id"
                  />
              </el-select>
              <div class="footnote">Leave blank if this is not a profile question.</div>
            </el-form-item>

            <el-form-item prop="youtube" v-show="slideData.type=='youtube'" label="Youtube video ID">
                <el-input v-model="slideData.youtube" placeholder="Video ID or URL" />
            </el-form-item>

            <el-form-item class="imageUpload" prop="image" v-show="slideData.type=='image'" label="Image">
                <file-upload filetype="image"
                  :current="slideData.initialImage"
                  @select="handleImageSelected"
                  @remove="handleImageRemoved"
                />
                <div class="footnote">File size must be less than 2MB.</div>
            </el-form-item>

            <el-form-item v-show="slideData.type=='upload'" prop="upload_intro" label="Introduction">
              <el-input name="upload_intro" type="textarea" v-model="slideData.upload_intro" placeholder="Paragraph" :rows="3" />
              <div class="footnote">Introductory paragraph describing the photos to upload.</div>
            </el-form-item>
            <el-form-item v-show="slideData.type=='upload'" prop="upload_question" label="Question">
              <el-input name="upload_question" type="textarea" v-model="slideData.upload_question" placeholder="Text prompt" :rows="3" />
              <div class="footnote">The participant will be prompted to enter a response for each photo.</div>
            </el-form-item>
            <el-form-item v-show="slideData.type=='upload'" prop="upload_count" label="Number of photos">
              <el-input-number name="upload_count" v-model="slideData.upload_count" :min="1" :max="10" />
            </el-form-item>

            <el-form-item prop="pdf" v-show="slideData.type=='pdf'" label="PDF">
              <file-upload filetype="pdf"
                :current="slideData.initialPdf"
                @select="handlePDFSelected"
                @remove="handlePDFRemoved"
              />
              <div class="footnote">File size must be less than 2MB.</div>
            </el-form-item>

            <el-form-item v-show="slideData.type=='activity' && learning" prop="activity_prompt" label="Prompt">
              <el-input name="activity_prompt" type="textarea" v-model="slideData.activity_prompt" placeholder="Text prompt" :rows="3" />
            </el-form-item>
            <el-form-item v-show="slideData.type=='activity' && learning" prop="activity_id" label="Include activity">
              <el-button @click="showActivityList = true">{{this.activityName ? this.activityName : 'Select an activity'}}</el-button>
            </el-form-item>

            <div class="dialog-footer">
              <el-button v-show="slideData.id || slideData.temp_id" type="danger" @click="deleteSlide">Delete slide</el-button>
              <el-button type="primary" @click="updateSlide">{{this.slideData.id || slideData.temp_id ? 'Update' : 'Add'}} slide</el-button>
            </div>

        </el-form>

        <el-dialog class="activityListDialog" append-to-body :visible.sync="showActivityList" title="Select an activity">
          <activity-list :limit="5" @select="selectActivity" />
        </el-dialog>

    </el-dialog>

</template>

<script>
    import RichEditor from '@/views/components/RichEditor';
    import FileUpload from '@/views/components/FileUpload';
    import ActivityList from '@/views/components/ActivityList';
    //import axios from 'axios';
    //import auth from '@/utils/auth';

    export default {
        name: 'slide-modal',
        components: {
            RichEditor,
            FileUpload,
            ActivityList,
        },
        props: {
          learning: {
            type: Boolean,
            required: false,
            default: false,
          }
        },
        computed: {
          activityName() {
            if(this.slideData.activity) {
              // new activity has been selected, use new value
              return this.slideData.activity.title;
            }
            return null;
          }
        },
        data() {
            return {
                showDialog: this.show,
                slideData: {},
                newSlideData: {
                  id: null,
                  title: '',
                  type: 'question',
                  text: null,
                  question: '',
                  file_url: '',
                  video_url: '',
                  file: null,
                  upload_count: 1,
                  // learning module fields
                  question_field_id: null,
                  activity_prompt: null,
                  activity_id: null,
                  youtube: null,
                  image: null,
                  pdf: null,
                  // working fields
                  initialType: null,
                  initialImage: null,
                  initialPdf: null,
                  upload_intro: '',
                  upload_question: '',
                  activity: null,
                },

                showActivityList: false,

                uploadError: false,

                rules: {
                  title: [{ required: true, trigger: 'blur', message: 'Slide title is required' }],
                  text: [{ required: true, validator: this.validateText, trigger: 'change' }],
                  question: [{ required: true, validator: this.validateQuestion, trigger: 'blur' }],
                  youtube: [{ required: true, validator: this.validateYoutube, trigger: 'blur' }],
                  image: [{ required: true, validator: this.validateImage, trigger: 'blur' }],
                  pdf: [{ required: true, validator: this.validatePdf, trigger: 'blur' }],
                  activity_id: [{ required: true, validator: this.validateActivity, trigger: 'change' }],
                  upload_intro: [{ required: true, trigger: 'blur', validator: this.validateUploadIntro }],
                  upload_question: [{ required: true, trigger: 'blur', validator: this.validateUploadQuestion }],
                  upload_count: [{ required: true, trigger: 'blur', validator: this.validateUploadCount }],
                },


                // PLACEHOLDER
                profile_fields: [
                  {id:1,name:'Profile question 1'},
                  {id:2,name:'Profile question 2'},
                  {id:3,name:'Profile question 3'},
                  {id:4,name:'Profile question 4'},
                ],

                //activities: [],
                //activitiesLoading: false,

                textValid: true,

            }
        },
        mounted() {
          this.$bus.$on('slide-modal', (data) => {
            this.slideData = {...this.newSlideData, ...data};
            this.slideData.initialType = data.type;
            this.slideData.initialImage = null;
            this.slideData.initialPdf = null;

            if(data.type == "image") {
              if(data.image) {
                this.slideData.initialImage = URL.createObjectURL(data.image);
              }
              else {
                this.slideData.initialImage = data.file_url;
              }
            }
            else if(data.type == "pdf") {
              if(data.pdf) {
                this.slideData.initialPdf = URL.createObjectURL(data.pdf);
              }
              else {
                this.slideData.initialPdf = data.file_url;
              }
            }
            else if(data.type == "youtube") { 
              this.slideData.youtube = data.video_url;
            }
            else if(data.type == "upload") { 
              this.slideData.upload_intro = data.text;
              this.slideData.upload_question = data.question;
            }
            
            this.showDialog = true;

            if(this.$refs.slideForm) {
              this.$refs.slideForm.clearValidate();
            }

            // if(!this.activities.length) {
            //   this.getActivities();
            // }
          });
        },
        methods: {
          handleImageSelected(file) {
            this.slideData.image = file;
          },
          handleImageRemoved() {
            this.slideData.initialImage = null;
            this.slideData.image = null;
          },

          handlePDFSelected(file) {
            this.slideData.pdf = file;
          },
          handlePDFRemoved() {
            this.slideData.initialPdf = null;
            this.slideData.pdf = null;
          },

          closeDialog() {
            this.showDialog = false;
          },

          updateSlide() {
            this.$refs.slideForm.validate((valid) => {
              if (valid) {
                // update in parent list
                if(!this.slideData.id && !this.slideData.temp_id) {
                  // add a temporary ID to identify this slide in the list until saved
                  this.slideData.temp_id = Date.now();
                }

                if(this.slideData.type == "image") { this.slideData.file = this.slideData.image; }
                else if(this.slideData.type == "pdf") { this.slideData.file = this.slideData.pdf; }
                else if(this.slideData.type == "youtube") { this.slideData.video_url = this.slideData.youtube; }
                else if(this.slideData.type == "upload") { this.slideData.text = this.slideData.upload_intro; this.slideData.question = this.slideData.upload_question; }
                this.$emit('update',this.slideData);
                this.showDialog = false;
              } else {
                return false;
              }
            });
          },
          deleteSlide() {
            // delete
            this.$emit('delete',this.slideData);
            this.showDialog = false;
          },

          validateText(rule,value,callback) {
            if(this.slideData.type == 'text' && !value) {
              if(this.slideData.text) {
                this.textValid = true;
              }
              else {
                this.textValid = false;
              }
            }
            callback();
          },
          validateQuestion(rule,value,callback) {
            if(this.slideData.type == 'question' && !value) {
              callback(new Error('Please enter a question for this slide'));
            }
            else callback();
          },
          validateYoutube(rule,value,callback) {
            if(this.slideData.type == 'youtube') {
              if(!value) {
                callback(new Error('Please enter a Youtube video ID for this slide'));
              }
              else if(value.includes('/')) {
                callback(new Error('Please enter a valid Youtube video ID'));
              }
              else callback();
            }
            else callback();
          },
          validateImage(rule,value,callback) {
            if(this.slideData.type == 'image' && !this.slideData.initialImage && !value) {
              callback(new Error('Please select an image for this slide'));
            }
            else callback();
          },
          validatePdf(rule,value,callback) {
            if(this.slideData.type == 'pdf' && !this.slideData.initialPdf && !value) {
              callback(new Error('Please select a PDF for this slide'));
            }
            else callback();
          },
          validateActivity(rule,value,callback) {
            if(this.slideData.type == 'activity' && !value) {
              callback(new Error('Please select an activity for this slide'));
            }
            else callback();
          },
          validateUploadIntro(rule,value,callback) {
            if(this.slideData.type == 'upload' && !value) {
              callback(new Error('Please enter the introductory text for this slide'));
            }
            else callback();
          },
          validateUploadQuestion(rule,value,callback) {
            if(this.slideData.type == 'upload' && !value) {
              callback(new Error('Please enter a question for the uploaded photos'));
            }
            else callback();
          },
          validateUploadCount(rule,value,callback) {
            if(this.slideData.type == 'upload' && (!value || value < 1 || value > 10)) {
              callback(new Error('Please enter a number between 1 and 10'));
            }
            else callback();
          },

          clearValidation() {
            this.$refs.slideForm.clearValidate();
          },

          textChange(text) {
            this.slideData.text = text;
            if(text) {
              this.textValid = true;
            }
            else {
              this.textValid = false;
            }
          },

          // getActivities() {
          //   this.activitiesLoading = true;
          //
          //   const token = await this.$auth.getTokenSilently();
          //
          //   var query = `${process.env.VUE_APP_BASE_URI}/activities?per_page=99999`;
          //   axios.get(query,{
          //     headers: {
          //       'Authorization': `Bearer ${token}`
          //     }
          //   })
          //   .then(response => {
          //       const data = response.data.data;
          //
          //       var activities = [];
          //       if(data && data.activities) {
          //         data.activities.forEach(activity => {
          //           activities.push({ ...activity });
          //         });
          //       }
          //       this.activities = activities;
          //       this.activitiesLoading = false;
          //
          //   })
          //   .catch((error) => {
          //       this.activities = [];
          //       this.activitiesLoading = false;
          //       console.log("Activities dropdown API error",error,error.response);
          //   });
          //
          // },

          selectActivity(activity) {
            this.showActivityList = false;
            this.slideData.activity = activity;
            this.slideData.activity_id = activity.id;
          }
        },
    }
</script>

<style lang="scss" scoped>
    .el-dialog {
        border-radius : 10px;
        overflow      : hidden;

        .el-select {
            width : 100%;
        }

        .el-date-editor {
            width : 100%;
        }

        .money {
            font-family : "Rubik";
        }

        .el-form-item.label-top {
          label {
            width: auto!important;
            float: none;
            display: inline-block;
            text-align: left;
            padding: 0;
          }
          .el-form-item__content {
            margin-left: 0!important;
          }
        }

        .el-checkbox {
          margin-bottom: 20px;
        }

/*
        .el-upload {
          display: inline-block;
          text-align: left;
        }

        .file-label {
          display: flex;
          align-items: center;
        }
        .file-name {
          flex-grow: 1;
          line-height: 1em;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .file-remove {
          font-size: 20px;
          color: #F56C6C;
          cursor: pointer;
        }
*/
        .dialog-footer {
          display: flex;
          > * {
            flex-grow: 1;
          }
        }
        .footnote {
          font-size: 12px;
          line-height: 2em;
        }

    }
</style>

<style lang="scss">
  .slideDialog .el-dialog {
    width: 800px;
    max-width: calc(100% - 40px);
    .el-dialog__body {
      word-break: normal;
    }
  }

  .activityListDialog .el-dialog {
    width: 1200px;
    max-width: calc(100% - 40px);
  }
  .imageUpload .el-form-item__content {
    line-height: 0;
  }
</style>
