<template>
  <div>
    <el-upload
      ref="fileUploader"
      class="fileUpload"
      action=""
      :class="{image: filetype == 'image'}"
      :accept="type"
      :http-request="selectFile"
      :show-file-list="false">

      <template v-if="filetype == 'image'">
        <template v-if="filePreview">
          <img :src="filePreview" class="fileImage">
          <el-button v-if="current && !file" size="mini" icon="el-icon-download" class="file-view-button" @click="viewFile" />
          <el-button type="danger" size="mini" icon="el-icon-delete" class="file-remove-button" @click="handleRemove" />
        </template>
        <template v-else>
          <i class="el-icon-plus image-icon"></i>
        </template>
      </template>

      <template v-else>
        <template v-if="filePreview">
          <!--<span class="file-name">{{fileName}}</span>
          <el-button type="danger" size="mini" icon="el-icon-delete" class="file-remove-button" @click="handleRemove" />-->
          <i class="file-icon el-icon-document" />
          <el-button v-if="current && !file" icon="el-icon-download" @click="viewFile" />
          <el-button type="danger" icon="el-icon-delete" @click="handleRemove" />

        </template>
        <template v-else>
          <el-button plain size="small" type="primary">Upload a {{this.filetype == 'pdf' ? 'PDF' : 'file'}}</el-button>
        </template>
      </template>

    </el-upload>

  </div>
</template>

<script>
    export default {
        name: 'file-upload',
        props: {
          filetype: { // mime type, or shorthand code (see computed type) for accepted file types
            type: String,
            required: false,
          },
          current: {
            type: String,
            required: false,
          }
        },
        data() {
          return {
            file: null,
          }
        },
        computed: {
          filePreview() {
            if(this.file) { return URL.createObjectURL(this.file); }
            else if(this.current) { return this.current; }
            else { return null; }
          },
          type() {
            if(!this.filetype) {
              return "";
            }
            else if(this.filetype == 'image') {
              return 'image/png, image/jpeg';
            }
            else if(this.filetype == "pdf") {
              return 'application/pdf';
            }
            else {
              return this.filetype;
            }
          },
        },
        methods: {
          selectFile(data) {
            this.file = data.file;
            this.$emit('select',data.file);
          },
          handleRemove(e) {
            e.stopPropagation();
            this.$refs.fileUploader.clearFiles();
            this.file = null;
            this.$emit('remove');
          },
          viewFile(e) {
            e.stopPropagation();
            if(this.current) {
              window.open(this.current,'_blank');
            }
          },

        },
        beforeDestroy() {
          console.log("file destroy");
          this.file = null;
        }
    };
</script>

<style lang="scss" scoped>

  /deep/ .fileUpload.image {
    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .el-upload:hover {
      border-color: #409EFF;
    }
    .image-icon {
      font-size: 28px;
      color: #8c939d;
      width: 150px;
      height: 150px;
      line-height: 150px;
      text-align: center;
    }
    .fileImage {
      width: 150px;
      height: 150px;
      display: block;
      object-fit: cover;
    }
    .file-name {
      flex-grow: 1;
      line-height: 1em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .file-view-button {
      position: absolute;
      bottom: 5px;
      left: 5px;
      border: 1px solid #e0e0e0;
    }
    .file-remove-button {
      position: absolute;
      bottom: 5px;
      right: 5px;
      border: 1px solid white;
    }
    .el-progress {
      background: white;
      position: absolute;
      top: 25px;
      left: 25px;
    }
  }
  /deep/ .fileUpload:not(.image) {
    .el-upload {
      display: inline-block;
      text-align: left;
      width: 100%;
    }
    .file-icon {
      vertical-align: middle;
      font-size: 40px;
      margin-top: -4px;
      margin-right: 10px;
    }
    /*
    .file-remove-button {
      margin-left: 20px;
    }
    */
  }
</style>

<style lang="scss">
  .is-error .fileUpload.image .el-upload {
    border-color: #F56C6C;
  }
</style>
