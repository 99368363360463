var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          ref: "fileUploader",
          staticClass: "fileUpload",
          class: { image: _vm.filetype == "image" },
          attrs: {
            action: "",
            accept: _vm.type,
            "http-request": _vm.selectFile,
            "show-file-list": false,
          },
        },
        [
          _vm.filetype == "image"
            ? [
                _vm.filePreview
                  ? [
                      _c("img", {
                        staticClass: "fileImage",
                        attrs: { src: _vm.filePreview },
                      }),
                      _vm.current && !_vm.file
                        ? _c("el-button", {
                            staticClass: "file-view-button",
                            attrs: { size: "mini", icon: "el-icon-download" },
                            on: { click: _vm.viewFile },
                          })
                        : _vm._e(),
                      _c("el-button", {
                        staticClass: "file-remove-button",
                        attrs: {
                          type: "danger",
                          size: "mini",
                          icon: "el-icon-delete",
                        },
                        on: { click: _vm.handleRemove },
                      }),
                    ]
                  : [_c("i", { staticClass: "el-icon-plus image-icon" })],
              ]
            : [
                _vm.filePreview
                  ? [
                      _c("i", { staticClass: "file-icon el-icon-document" }),
                      _vm.current && !_vm.file
                        ? _c("el-button", {
                            attrs: { icon: "el-icon-download" },
                            on: { click: _vm.viewFile },
                          })
                        : _vm._e(),
                      _c("el-button", {
                        attrs: { type: "danger", icon: "el-icon-delete" },
                        on: { click: _vm.handleRemove },
                      }),
                    ]
                  : [
                      _c(
                        "el-button",
                        {
                          attrs: { plain: "", size: "small", type: "primary" },
                        },
                        [
                          _vm._v(
                            "Upload a " +
                              _vm._s(this.filetype == "pdf" ? "PDF" : "file")
                          ),
                        ]
                      ),
                    ],
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }